.container {
  height: 100vh;
  padding: 5rem;
  box-sizing: border-box;
}

.vector-graphic {
  filter: drop-shadow(0 40px 10px rgba(0, 0, 0, 0.3));
  animation: vectorAnimation 1s infinite ease-in-out alternate;
}

@keyframes vectorAnimation {
  to {
    transform: translateY(-10px);
  }
}

.brands-banner > svg {
  color: white;
  font-size: 3rem;
  transition: color 0.5s;
}

.brands-banner > svg:hover {
  color: rgb(225, 221, 0);
}

.video-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container > video {
  border: 1px solid rgba(0, 0, 0, 0.18);
  width: 60%;
  border-radius: 5px;
  outline: none;
}

@media screen and (max-width: 600px) {
  .video-container {
    height: unset;
    padding: 8vh 0;
  }
  .video-container > video {
    width: 100%;
  }
}

.course {
  margin-bottom: 1rem;
  width: 250px;
  transition: all 0.5s;
}

.course:hover {
  transform: translateY(-10px);
}

.StripeElement {
  border-width: 2px;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #4a5568;
  background-color: #ffffff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out;
}

.StripeElement--focus {
  border-color: #d69e2e;
}

.StripeElement.dark {
  background-color: #2d3748 !important;
  color: #fff !important;
}
